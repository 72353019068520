<template>
  <div>
    Email Settings
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EmailSettings',
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
    }),
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
